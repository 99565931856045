$color-bg-000: #121212; //#ffffff;
$color-bg-100: #222222; //#f9f9f9;
$color-bg-200: #222324; //#eeeeee;
$color-bg-300: #363636; //#dddddd;

$color-text-000: #ffffff;
$color-text-100: #dddddd; //#202020; //darkest
$color-text-200: #aaaaaa; //#555555; //darker
$color-text-300: #aaaaaa; //#595959; //dark
$color-text-400: #666666; //normal
$color-text-500: #777777; //#777777; //ligth
$color-text-600: #999999; //ligther
$color-text-700: #aaaaaa; //
$color-text-800: #bfd0e9; //#cccccc;
$color-text-900: #444444; //#dddddd;
$color-text-1000: #aaaaaa;

$color-border-100: #363636; //#d9d9d9;
$color-border-200: #363636; //#d9d9d9;
$color-shadow: #ffffff; //#cccccc;

/*
$base-bg:                #ffffff !default;
$base-bg-darker:         #eeeeee !default;
$base-bg-dark:           #f9f9f9 !default;
$base-border:            #d9d9d9 !default;
$base-color:             #202020 !default;
*/

$gray-base: #dddddd; //#202020;
$gray-darker: #aaaaaa; //#555555;
$gray-dark: #777777; //#777777;
$gray: #363636; //#dddddd;
$gray-light: #444444; //#eeeeee;
$gray-lighter: #222222; //#f9f9f9;

$close-color: #fff !default;
$close-text-shadow: 0 1px 0 #000 !default;

$body-bg: $color-bg-200;
$checkbox-background-color: $color-bg-100 !default;
$navbar-main-icon-color: $color-text-600 !default;
$panel-default-text: $color-text-100 !default;
$widget-content-color: $color-text-300 !default;
$widget-content-icon-color: $color-text-500 !default;
$widget-col-background-color: $color-bg-100 !default;

$input-bg-disabled: $color-bg-200 !default;
$input-color-focus: $color-text-000 !default;
$input-border-focus: $color-border-100 !default;
$input-bg-focus: #111111 !default;
$input-color-placeholder: $color-text-500 !default;
$sidebar-bg: $color-bg-200 !default;
$ph-item-bg: $color-bg-000 !default;
$scrollbar-track-bg: $color-bg-200 !default;
$scrollbar-track-border: $color-bg-200 !default;
$scrollbar-size: 0px !default;
$component-active-bg: $color-text-800 !default;
$modal-content-bg: $color-bg-200 !default;
$progress-bg: $color-bg-200 !default;
$bg-yellow: #342900 !default;
$skeletron-color: $color-bg-000 !default;


$btn_bg_reports: #2F2F2D;
$btn_bg_alerts: #312325;
$btn_bg_share_location: #1a251d;
$btn_bg_active_device: #162426;


$btn_color_reports: #e286ff;
$btn_color_alerts: #ea5355;
$btn_color_share_location: #29c76f;
$btn_color_active_device: #04cfe7;
$color-sensor-modal-new: #BBBBBB;